import { AnchorLink, AnchorLinkType } from '../../anchorLink';
import { Icon, IconsEnum } from 'components/Icons';
import { MenuItemBlock, Navigation } from '../Header';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { gtag, isMatch, isNotEmpty } from 'utility/functions';

import { AppFragment } from 'enums/path-fragment';
import { DetailsMenu } from '../detailsMenu';
import { HeaderMenuItem } from '../headerMenuItem';
import classNames from 'classnames';
import { selectIsActiveVirtual } from 'features/configuration/selectors';
import styles from './HeaderNavigation.module.scss';
import { useInternalSession } from 'hooks/useInternalSession';
import { useRouter } from 'next/router';
import { useTypedSelector } from 'lib/centralStore';

export type HeaderNavigationProps = {
  id: number | string | null;
  navigation?: (Navigation | AnchorLinkType)[];
  allNavigationList?: (Navigation | AnchorLinkType)[];
  handleShowDetailsMenu: Function;
  showDetail: boolean;
  indexItem: number | string;
};

// const sportChild = [AppFragment.Live, AppFragment.Ippica];

// const objIcon = <Icon iconId={IconsEnum.CHEWRON_DOWN} color="var(--color-primary)" />;
export const HeaderNavigation: React.FC<HeaderNavigationProps> = ({
  id,
  indexItem,
  showDetail,
  navigation,
  allNavigationList,
  handleShowDetailsMenu,
}: HeaderNavigationProps) => {
  // const idSport = '64023360';
  const idVirtual = '64024247';
  const { session } = useInternalSession();

  const redirectIdsWhitelist = useRef<Record<string, boolean>>({});
  const isVirtualActive = useTypedSelector(selectIsActiveVirtual);

  const { asPath } = useRouter();

  const isActiveElement = useCallback(
    (item: MenuItemBlock[] | AnchorLinkType) => {
      if (Array.isArray(item)) {
        return item.some(({ linkBlock }: MenuItemBlock) => {
          const pattern = `${linkBlock?.[0]?.href}`
            .split('/')
            .filter((x) => isNotEmpty(x))
            .join('\\/');
          return isMatch(asPath, `^(\\/?)${pattern}(\\/|\\?|$)`);
        });
      }
      const pattern = `${item.href}`
        .split('/')
        .filter((x) => isNotEmpty(x))
        .join('\\/');
      return isMatch(asPath, `^(\\/?)${pattern}(\\/|\\?|$)`);
    },
    [asPath]
  );

  const getUrl = useCallback(
    (item: Navigation) => {
      const href = item.navigationItemBlock![0].href!;

      if (redirectIdsWhitelist.current[item.id] === false) {
        return session
          ? `${process.env.NEXT_PUBLIC_SEAMLESS_BASE_URL}/seamless-login/accesso/${session?.user?.token}/${session?.user?.cardNumber}/newsito/1/`
          : `${process.env.NEXT_PUBLIC_SEAMLESS_BASE_URL}/${href.toString().replace(/\//, '')}`;
      }
      return href;
    },
    [redirectIdsWhitelist, session]
  );

  useEffect(() => {
    if (isVirtualActive !== undefined) {
      redirectIdsWhitelist.current = {
        [idVirtual]: isVirtualActive,
      };
    }
  }, [isVirtualActive]);

  const itemClasses = useMemo(() => {
    return {
      item: styles.item,
      itemActive: styles.itemActive,
      itemDisable: styles.itemDisable,
      itemFadeLine: styles.itemFadeLine,
      itemRouterActive: styles.itemRouterIsActive,
      itemRouteDisable: styles.itemRouterIsDisable,
      itemIsInHome: styles.itemIsInHome,
    };
  }, []);

  return (
    <nav className={styles.navigation}>
      <ul className={styles.list}>
        {navigation?.map((singleItem: Navigation, index) => {
          const { menuItemBlock } = singleItem.navigationItemBlock![0];
          if (menuItemBlock) {
            const isOpenDropdown = indexItem === singleItem.id && showDetail;
            const className = classNames(styles.arrow, isOpenDropdown ? styles.arrowUp : styles.arrowDown);
            const itemIcon = (
              <Icon iconId={IconsEnum.CHEWRON_DOWN} className={className} color="var(--color-primary)" />
            );

            return (
              <HeaderMenuItem
                id={id}
                key={+singleItem.id}
                currentId={singleItem.id}
                itemClasses={itemClasses}
                isOpenDropdown={isOpenDropdown}
                isActiveElement={isActiveElement(menuItemBlock)}
              >
                <button
                  type="button"
                  className={styles.buttonItem}
                  aria-expanded={isOpenDropdown ? 'true' : 'false'}
                  onClick={() => handleShowDetailsMenu(singleItem.id, allNavigationList ?? [])}
                >
                  {singleItem.label}
                  {itemIcon}
                </button>
                <DetailsMenu
                  index={index}
                  showDetail={isOpenDropdown}
                  currentIndex={+indexItem}
                  menuItemBlock={menuItemBlock}
                  gtagAction={(page: string) =>
                    gtag({ section: 'Overall', detail: `Navigation ${page}`, event: 'Click Navigation' }, true)
                  }
                ></DetailsMenu>
              </HeaderMenuItem>
            );
          }
          return (
            <HeaderMenuItem
              id={id}
              key={singleItem.id}
              currentId={singleItem.id}
              itemClasses={itemClasses}
              isActiveElement={isActiveElement(singleItem.navigationItemBlock![0])}
            >
              <AnchorLink
                onClick={() =>
                  gtag(
                    { section: 'Overall', detail: `Navigation ${singleItem.label}`, event: 'Click Navigation' },
                    true
                  )
                }
                href={getUrl(singleItem)}
                className={styles.anchorlink}
              >
                {singleItem.label}
              </AnchorLink>
            </HeaderMenuItem>
          );
        })}
      </ul>
    </nav>
  );
};
