import {
  DatoCmsCertificationsBlock,
  DatoCmsHeader,
  DatoCmsImage,
  DatoCmsImageTheme,
  DatoCmsImageThemeWithUrl,
  DatoCmsMenuItemBlock,
  DatoCmsMightLike,
  DatoCmsNavigation,
  DatoCmsNeedHelp,
} from '../../lib/datoCms/types';
import { InstantPanel, InstantPanelProps, InstantPanelTogglerProps } from '../instantPanel/InstantPanel';
import { MenuItem, NavigationBlock } from '../footer/footerNavigation/FooterNavigation';
import Persist, { StorageKind } from 'lib/persist';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { convertInImgType, convertInThemeLogoType } from '../../lib/datoCms/utils';
import { isClientSide, isFrame, isTruthy } from 'utility/functions';
import {
  selectIsOpenLoginModal,
  selectIsOpenNewsDialog,
  setIsOpenLoginModalValue,
} from '../../features/modal/modalSlice';
import { selectModalTutorial, selectSmartBanner } from 'features/configuration/selectors';
import { useAppDispatch, useTypedSelector } from 'lib/centralStore';
import { BackgroundImage } from 'components/backgroundImage';
import { CertificationBlock } from '../footer/footerDisclaimer/FooterDisclaimer';
import { FROM_REGISTRATION } from 'utility/constant';
import { Footer } from '../footer';
import Head from 'next/head';
import { Header } from '../header';
import { ImageProps } from 'next/image';
import { InactiveMessage } from 'components/wrapperInactiveLogout';
import { LoginModal } from '../modals/loginModal';
import { MightLikeContext } from '../../context/Mightlikes';
import { NeedHelp } from '../needHelp';
import { NewsDialog } from 'components/newsDialog';
import { SeamlessLoginComponent } from 'components/seamlessLoginComponent';
import { SmartBanner } from 'components/smartBanner/SmartBanner';
import { SnaiPlusFrame } from 'components/snaiPlusFrame';
import { WelcomeModal } from 'components/welcomeModal';
import classNames from 'classnames';
import { selectIsLoadingVendita } from 'features/carrello/carrelloSelectors';
import { selectStringAutoesclusion } from 'features/dashboard/selectors';
import styles from './Layout.module.scss';
import useAgentDetect from 'hooks/useAgentDetect';
import { useIsSeamlessMode } from 'hooks';
import { useNewsDialogHooks } from 'components/newsDialog/useNewsDialogHooks';
import { useSelector } from 'react-redux';
import { ButtonOpenInstantPanel } from 'components/instantPanel/buttonOpenInstantPanel';

const isFrameDocument = isFrame();

export type LayoutProps = {
  header?: DatoCmsHeader;
  footer?: any;
  needHelp?: DatoCmsNeedHelp;
  children?: ReactNode;
  fullWidth?: boolean;
  mightLikes?: DatoCmsMightLike[];
  sidebarInstantGame?: InstantPanelTogglerProps;
  headerOnlyLogo?: boolean;
};

export const Layout = ({
  header,
  footer,
  children,
  needHelp,
  fullWidth,
  mightLikes,
  sidebarInstantGame,
  headerOnlyLogo,
}: LayoutProps) => {
  const dispatch = useAppDispatch();
  const seamlessMode = useIsSeamlessMode();
  const modalTutorial = useTypedSelector(selectModalTutorial);
  const isLoadingVendita = useTypedSelector(selectIsLoadingVendita);
  const isOpenNewsDialog = useTypedSelector(selectIsOpenNewsDialog);
  const isOpenLoginModal = useSelector(selectIsOpenLoginModal);
  const stringAutoesclusion = useTypedSelector(selectStringAutoesclusion);
  const selectedBanner = useTypedSelector(selectSmartBanner);
  const [openPanel, setOpenPanel] = useState<boolean>(false);

  const { isSafari, isIos, isIPhone, isMac } = useAgentDetect();
  const { isVisible, setHidden } = useNewsDialogHooks();
  const sessionStorage = Persist(StorageKind.session);

  const contentViewPort = useMemo(() => {
    if (isIPhone) return 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, viewport-fit=cover';
    return 'initial-scale=1.0, width=device-width, minimum-scale=1';
  }, [isIPhone]);

  useEffect(() => {
    if (isClientSide()) {
      document.body.classList.remove('bodyOs');
      document.body.classList.remove('bodyOsDesktop');
      document.body.classList.remove('bodyNotOsDesktop');

      if (isIos && isSafari) {
        document.body.classList.add('bodyOs');
      } else if (isMac) {
        document.body.classList.add('bodyOsDesktop');
      } else {
        document.body.classList.add('bodyNotOsDesktop');
      }
    }
  }, [isIos, isMac, isSafari]);

  useEffect(() => {
    if (stringAutoesclusion) {
      dispatch(setIsOpenLoginModalValue(true));
    }
  }, [stringAutoesclusion, dispatch]);

  const renderFooter = () => {
    if (seamlessMode || !footer) {
      return null;
    }

    const {
      navigation,
      ippicaLogoList,
      paymentLogoList,
      certificationsBlock,
      settingsTitle,
      settingsLabelTema,
      switchTheme,
      ippicaTitle,
      disclaimer,
      logoAdm18,
      avvisoAdm,
    } = footer;

    const navigationRenamed = navigation.map((item: DatoCmsNavigation): NavigationBlock => {
      const menuList: MenuItem[] = item.menu[0].menuItemBlock.map((itemMenu: DatoCmsMenuItemBlock): MenuItem => {
        const { id, label, linkBlock: links } = itemMenu;
        const menuItem: MenuItem = { id, label, links };
        return menuItem;
      });
      return {
        id: item.id,
        title: item.title,
        menuList: menuList,
      };
    });

    const ippicaLogoListRenamed = ippicaLogoList.map((item: DatoCmsImage): ImageProps => {
      return convertInImgType(item);
    });

    const paymentLogoListRenamed = paymentLogoList.map((paymentLogo: DatoCmsImageTheme) => {
      return convertInThemeLogoType(paymentLogo);
    });

    const certificationsBlockRenamed = certificationsBlock.map(
      (item: DatoCmsCertificationsBlock): CertificationBlock => {
        const logos: DatoCmsImageThemeWithUrl[] = item.logo;
        const logoThemeList = logos.map((logo) => convertInThemeLogoType(logo));
        return {
          id: item.id,
          title: item.title,
          logoList: logoThemeList,
        } as CertificationBlock;
      }
    );

    return (
      <Footer
        warningText={avvisoAdm}
        ippicaTitle={ippicaTitle}
        warningLogo={logoAdm18.url}
        switchTheme={switchTheme[0]}
        settingsTitle={settingsTitle}
        ippicaLogoList={ippicaLogoListRenamed}
        paymentLogoList={paymentLogoListRenamed}
        footerNavigation={navigationRenamed}
        settingsLabelTema={settingsLabelTema}
        disclaimerParagraph={disclaimer}
        certificationsBlock={certificationsBlockRenamed}
      />
    );
  };

  return (
    <MightLikeContext.Provider value={mightLikes!}>
      <div className={classNames({ [styles.isLoadingVendita]: isLoadingVendita })} suppressHydrationWarning>
        <Head>
          <meta key={'marcatori-viewport-def'} name="viewport" content={contentViewPort} />
        </Head>
        {!!selectedBanner && <SmartBanner />}
        {header && !seamlessMode && (
          <Header
            logoSnaiPi={header.logoSnaiPi[0]}
            logoBrand={convertInThemeLogoType(header.logoBrand[0])}
            navigationList={header.navigationList}
            promoHref={header.promoHref}
            profileHref={header.profileHref}
            snaiMessageHref={header.snaiMessageHref}
            userNavigationList={header.userNavigationList}
            mobileUserNavigationList={header.mobileUserNavigationList}
            onlyLogo={headerOnlyLogo}
            userPanel={header.userPanelList}
            disclaimer={header.disclaimer}
          />
        )}
        <WelcomeModal />
        <InactiveMessage />
        <SeamlessLoginComponent />
        <BackgroundImage containerClassName={styles.containerLogo} imageClassName={styles.logo} />
        <main className={classNames(styles.container, { [styles.fullWidth]: fullWidth })}>
          {modalTutorial && isOpenNewsDialog && isVisible && !isFrameDocument && !seamlessMode && (
            <NewsDialog onHideModal={(value: boolean) => setHidden(value)} {...modalTutorial} />
          )}
          {children}
          {sidebarInstantGame && (
            <ButtonOpenInstantPanel image={sidebarInstantGame?.image} setOpenPanel={setOpenPanel} />
          )}
          {openPanel && <InstantPanel setOpenPanel={setOpenPanel} />}
          {needHelp && !seamlessMode && (
            <div className={styles.container}>
              <NeedHelp heading={needHelp.title} optionList={needHelp.option} />
            </div>
          )}
        </main>
        {renderFooter()}
        {header && (
          <LoginModal
            logoBrand={convertInThemeLogoType(header.logoBrand[0])}
            isOpen={isOpenLoginModal}
            handleClose={() => {
              dispatch(setIsOpenLoginModalValue(false));
              if (isTruthy(sessionStorage.getItem(FROM_REGISTRATION))) sessionStorage.removeItem(FROM_REGISTRATION);
            }}
          />
        )}
      </div>
      <SnaiPlusFrame />
    </MightLikeContext.Provider>
  );
};
