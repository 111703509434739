import { Icon, IconsEnum } from 'components/Icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SlotCard, SlotCardProps } from '../cards/slotCard/SlotCard';
import { gamePlatforms, launchGame } from 'lib/launchGame/utils';
import { isNotEmpty, isTruthy, isMatch } from 'utility/functions';
import { useAppDispatch, useTypedSelector } from 'lib/centralStore';
import { DynamicImage } from 'components/Image';
import { GamesCardType } from 'components/cards/gamesCard';
import { LoadingSpinner } from 'components/spinner';
import Script from 'next/script';
import { appInsight } from 'components/appInsight';
import classNames from 'classnames';
import { getCookie } from 'lib/persist/cookie';
import { getNonce } from 'lib/policy';
import { selectInstantScriptLoaded } from 'features/configuration/selectors';
import { setInstantScriptLoaded } from 'features/configuration/configurationSlice';
import styles from './InstantPanel.module.scss';
import { useCodeGameByWindowSize } from 'hooks/useCodeGameByWindowSize';
import { useGetLabelsByKeys } from 'hooks/useLingUI';
import { useInternalSession } from 'hooks/useInternalSession';
import useWindowSize from 'hooks/useWindowSize';
import { useLazyGetInstantPanelQuery } from 'features/api/externalApiSlice';
import { useRouter } from 'next/router';

export type InstantPanelTogglerProps = {
  image?: {
    id?: string;
    alt?: string;
    url: string;
  };
};

export type InstantPanelProps = {
  instantRoulette?: GamesCardType;
  instantSlots?: SlotCardProps[];
  image: {
    id?: string;
    alt?: string;
    url: string;
  };
};

export const InstantPanel = (props: any) => {
  const { locale } = useRouter();
  const { setOpenPanel } = props;

  const [getDataPanel, { data, isLoading }] = useLazyGetInstantPanelQuery();
  useEffect(() => {
    getDataPanel(locale);
  }, []);

  const instantRoulette = data?.instantPanel?.instantRoulette;
  const instantSlots = data?.instantPanel?.instantSlots;

  const dispatch = useAppDispatch();
  const { isMobile } = useWindowSize();

  const isScriptLoaded = useTypedSelector(selectInstantScriptLoaded);
  const [labelInstantSlot, labelInstantRoulette, labelNascondiPer24h] = useGetLabelsByKeys([
    'instant-slot',
    'instant-roulette',
    'nascondi-per-24-ore',
  ]);

  const [activePanel, setActivePanel] = useState<string | null>();
  const [injectScript, setInjectScript] = useState<boolean>(false);
  const refLaunched = useRef(false);

  const gameConfigSrc = useMemo(
    () => gamePlatforms[instantRoulette?.codicePiattaforma as keyof typeof gamePlatforms]?.urlParams?.FIM_CONF,
    [instantRoulette?.codicePiattaforma]
  );

  const [url, setUrl] = useState<string>();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [componentIsLoading, setComponentIsLoading] = useState<boolean>(false);

  const hasScriptToLoad = useMemo(() => injectScript && gameConfigSrc, [gameConfigSrc, injectScript]);

  const [codeGameRoulette] = useCodeGameByWindowSize(
    instantRoulette?.playInfo.codeGameDesktop!,
    instantRoulette?.playInfo.codeGameMobile!
  );

  const {
    session: { user = undefined },
  } = useInternalSession();

  useEffect(() => {
    if (!gameConfigSrc) {
      dispatch(setInstantScriptLoaded(true));
    }
  }, [dispatch, gameConfigSrc]);

  // RESET URL AND LAUNCH WHEN SESSION CHANGES
  useEffect(() => {
    if (!user) {
      setUrl(undefined);
      refLaunched.current = false;
    }
  }, [user]);

  // TRIGGER LAUNCH INSTANT GAME
  const loadRoulette = useCallback(
    async (cardNumber: string) => {
      setComponentIsLoading(true);
      try {
        const urlResponse = await launchGame({
          ...(instantRoulette as any),
          isMobile,
          isInstant: true,
          cardNumber,
          isFromPanel: true,
          gameCode: codeGameRoulette,
          product: instantRoulette?.playInfo?.product,
        });
        setUrl(urlResponse || undefined);
      } catch (error) {
        console.error(`unable to launch game`, error);
        appInsight?.trackException({
          exception: error,
          id: 'instan-panel',
          properties: {
            gameCode: codeGameRoulette,
            cardNumber,
          },
        });
        refLaunched.current = false;
      } finally {
        setComponentIsLoading(false);
      }
    },
    [codeGameRoulette, instantRoulette, isMobile]
  );

  useEffect(() => {
    const { cardNumber } = user ?? {};
    if (codeGameRoulette && isScriptLoaded && !!cardNumber && !disabled && !refLaunched.current) {
      refLaunched.current = true;
      loadRoulette(cardNumber);
    }
  }, [user, instantRoulette, codeGameRoulette, isScriptLoaded, isMobile, disabled, loadRoulette]);

  const setCookie = useCallback((cname: string, cvalue: string) => {
    let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    let expires = 'expires=' + tomorrow.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }, []);

  useEffect(() => {
    const get = getCookie('instant-roulette-disabled');
    if (get) {
      setDisabled(true);
    }
    setInjectScript(true);
  }, []);

  useEffect(() => {
    setActivePanel(!instantRoulette ? labelInstantSlot : labelInstantRoulette);
  }, [labelInstantRoulette, labelInstantSlot, instantRoulette]);

  if (!instantRoulette && !instantSlots?.length) {
    return null;
  }

  return (
    <aside className={classNames(styles.aside, styles.visible)}>
      <div className={classNames(styles.container, styles.panelVisible)}>
        <div className={styles.tabs}>
          <div className={classNames(styles.tab, { [styles.disabled]: !instantRoulette })}>
            <h3
              onClick={() => instantRoulette && setActivePanel(labelInstantRoulette)}
              className={classNames({ [styles.activeTitle]: activePanel === labelInstantRoulette })}
            >
              {labelInstantRoulette}
            </h3>
          </div>
          {instantSlots && (
            <div className={styles.tab} onClick={() => setActivePanel(labelInstantSlot)}>
              <h3 className={`${activePanel === labelInstantSlot && styles.activeTitle}`}>{labelInstantSlot}</h3>
            </div>
          )}
          <button className={styles.closeButton} onClick={() => setOpenPanel(false)}>
            <Icon className={styles.closeIcon} iconId={IconsEnum.CLOSE} color="var(--color-on-bg-primary)" />
          </button>
        </div>

        <div className={styles.contentSection}>
          <div
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            className={classNames(styles.rouletteContent, {
              [styles.activeContent]: activePanel === labelInstantRoulette,
            })}
          >
            {componentIsLoading ? (
              <LoadingSpinner className={styles.spinner} />
            ) : url ? (
              <iframe src={url} height="100%" width="100%" nonce={getNonce()} />
            ) : (
              <div className={styles.card}>
                <SlotCard {...(instantRoulette as any)} isHover={isHover} />
              </div>
            )}
          </div>
          {isTruthy(instantSlots?.length) && (
            <div
              className={classNames(styles.slotContent, { [styles.activeContent]: activePanel === labelInstantSlot })}
            >
              <div className={styles.cardListContainer}>
                {instantSlots?.map((item) => {
                  return (
                    <div className={styles.card} key={item.id}>
                      <SlotCard {...{ ...item, functionality: [] }} />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <button
          type="button"
          className={styles.hideButton}
          onClick={() => {
            setCookie('instant-roulette-disabled', 'true');
            setDisabled(true);
            setOpenPanel(false);
          }}
        >
          <Icon className={styles.hideIcon} iconId={IconsEnum.EYE_OFF} color="var(--color-on-bg-primary)" />
          <span>{labelNascondiPer24h}</span>
        </button>
      </div>
      {hasScriptToLoad && isMatch(gameConfigSrc, '^http') && (
        <Script src={gameConfigSrc} onLoad={() => dispatch(setInstantScriptLoaded(true))} />
      )}
    </aside>
  );
};
